import React from 'react';
import { LanguageContext } from '../../App';
import Asset_3 from '../../assets/Asset 3.svg';
import './lectures.css'

const lectureList = [
    'أمسية شعرية - نادي ابها الادبي	',
    'محاضرة 2',
    'محاضرة 3',
    'محاضرة 4',
    'محاضرة 5',
    'امسية شعرية - نادي المنطقة الشرقة الادبي',
    'امسية شعرية',
    'امسية شعرية',
    'محاضرة 6',
    'محاضرة 7'
]

const lectures = {
    en:{
        header:'Lectures',
        title:'Post Title',
        category:'Category',
        view:'View',
        viewButton:'View'
    },
    ar:{
        header:'محاضرات',
        title:'العنوان',
        category:'التصنيف',
        view:'عرض',
        viewButton:'لرؤية محتوى'
    }
}

function Lectures(props) {
    const  {lang='ar'} = React.useContext(LanguageContext)
    return (
        <div className="mt-5 topview">
            <p className="text-center ff-bahij title-fs">{lectures[lang].header}</p>
            <div className="container container-fix bg mt-5" style={{ "position": "relative" }}>
                <div className="row align-items-center">
                    <div className={"col-3 col-md-2 pt-4 "+(lang==="en"?"text-left ff-myriad-thin pl2":"text-center lang-ff")}>{lectures[lang].category}</div>
                    <div className={"col-6 col-md-7 pt-4 fs-title "+(lang==="en"?"text-left ff-bahij ":"text-right lang-ff")}>{lectures[lang].title}</div>
                    <div className={"col-2 col-md-2 text-center pt-4 fs-view  "+(lang==="en"?"ff-myriad-thin":"lang-ff")}>{lectures[lang].view}</div>

                    <hr width="100%;" color="#c9c9c7" size="10" />
                    {lectureList.map((lecture, i) => (
                        <>
                            <div className={"col-3 col-md-2 py-3 my-auto "+(lang==="en"?"text-left ff-myriad-thin pl2 sm-title-poem":"text-center lang-ff")}>{lectures[lang].header}</div>
                            <div className={"col-6 col-md-7 pt-2 my-auto lang-ff fs-title-content "+(lang==="en"?"text-left  lang-ff":"text-right lang-ff")}>{lecture}</div>
                            <div className="col-2 col-2 py-3 px-0 text-center">
                                <button className={"btn btn-change "+(lang==="en"?"ff-myriad-thin":"lang-ff")}  onClick={()=>props.history.push(`/lectures/view/${i+19}`)}>{lectures[lang].viewButton}</button>
                            </div>
                        </>
                    ))}

                </div>
                <img src={Asset_3} alt="brandImages" className="trademark" />


            </div>
        </div>
    )
}

export default Lectures;