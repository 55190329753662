export const booksList = {
    Formal_Poems: [
        {text:'قصائد راعفة	', font:'lang-ff text-break'},
        {text:'وشوم على جدار الوقت	', font:'lang-ff text-break'},
        {text:'فجر أنت لا تغب	', font:'lang-ff text-break'},
        {text:'هجرة القمر', font:'lang-ff text-break'},
        {text:'مد والشاطىء انت	', font:'lang-ff text-break'},
        {text: 'المداد', font:'lang-ff text-break'},
        {text:'غربة', font:'lang-ff text-break'},
        {text:'خيام القبيلة', font:'lang-ff text-break'},
        {text:'نقطة في تضاريس الوطن', font:'lang-ff text-break'},
        {text: 'Book News Paper Cuttings', font:'ff-myriad-thin text-break'},
        {text: 'المواكب الصاعدة', font:'lang-ff text-break'},
        {text: 'خواطر صريحة', font:'lang-ff text-break'},
        {text: 'رسالة عشق', font:'lang-ff text-break'},

    ],
    Translated_Poems: [
        {text:'Migration de la lune', font:'ff-myriad-thin text-break'},
        {text:'The Tents Of The Tribe', font:'ff-myriad-thin text-break'},
        {text: 'book3', font:'ff-myriad-thin text-break'}        
       
    ],
    Development_Books: [
        {text:'التنمية وعربة الكرو', font:'lang-ff text-break'},
        {text:'book12	', font:'ff-myriad-thin text-break'},
        {text:'Bureaucracy and society in Saudi Arabia', font:'ff-myriad-thin text-break'},
        {text:'file4', font:'ff-myriad-thin text-break'},
        {text:'file5', font:'ff-myriad-thin text-break'},
        {text:'file6', font:'ff-myriad-thin text-break'},
        {text:'file8', font:'ff-myriad-thin text-break'},
        {text:'file9', font:'ff-myriad-thin text-break'},
        {text:'Role of research in policy making', font:'ff-myriad-thin text-break'},
        {text:'الرؤية والطبقة الوسطى', font:'lang-ff text-break'},
        {text:'قراءات في الاعمال الكاملة', font:'lang-ff text-break'},
        {text: 'A Statment on the human resources planning', font:'ff-myriad-thin text-break'},
    ],
    Traditional_Poems: [

    ],
    Journalism: [
        {text:'file_00001 (2).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00001 (3', font:'ff-myriad-thin text-break'},
        {text:'file_00001 (4).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00001 (5).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00001 (6).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00001 (7).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00001 (8).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00001 (9).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00001.pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00002 (2).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00002.pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00003 (2).pdf', font:'ff-myriad-thin text-break'},
        {text:'file_00003.pdf', font:'ff-myriad-thin text-break'},
        {text:'File3', font:'ff-myriad-thin text-break'},
        {text:'File7', font:'ff-myriad-thin text-break'}
    ]
};
