import React from 'react';
import './home.css';
import Asset_4 from '../../assets/Asset_4.png';
import Asset_5 from '../../assets/alawaji.svg';
import Asset_3 from '../../assets/Asset 3.svg';
import Book_1 from '../../assets/Book_1.png';
import Book_2 from '../../assets/Book_2.png';
import Book_3 from '../../assets/Book_3.png';
import Book_4 from '../../assets/Book_4.png';
import { LanguageContext } from '../../App';

const home = {
    en: {
        title: 'About',
        content: 'Contrary to the majority of intellectuals, writers and poets whose bid had the opportunity to spread for the purpose of publishing, display and media, for reasons related to my circumstances and despite the multiplicity of my bids I did not reach the minimum level of spread first because many of my poetry and prose work was done at a time when I was working as an agent for the Ministry of Interior for 25 years secondly Because the Kingdom, despite the cognitive and material progress, does not have professional publishing houses capable of absorbing all the intellectual and cultural outputs in the country, with all due respect to those in it, because the publishing houses in the world have a pivotal role in presenting and highlighting the literary and intellectual works of the peoples, while we have only printers and offices,And as I mentioned, because of my work I published most of my books by literary clubs such as Al-Taif Club. This is why it was not published enough and people always ask me about my books, for example, which pushed me to get all my work out with a comprehensive site. Anyone interested in something of my production can see what he wants and retrieve what he wants without having to search in libraries. Or ask me personally to someone who knows me.',
        content1:`On this site: Nine collections are “The ink and tattoos on the wall of time, the stretch and the beach. There are a lot of my recorded and written lectures on the site, my press interviews, my books, "Development and the Football, The Vision and the Middle Class," and my Master's thesis "Development Management in the Kingdom of Saudi Arabia" and the Ph.D. "Bureaucracy and Saudi Society."`,
        content2:'And the God of the intent behind'
    },
    ar: {
        title: 'لماذا هذا الموقع؟',
        content:'خلافاً للأغلبية من المثقفين والكتاب والشعراء الذين توافرت لعطاءآتهم فرص الانتشار بهدف وسائل النشر والعرض والإعلام فأنني لأسباب تتعلق بظروفي ورغم تعدد عطاءآتي لم اصل إلى الدرجة الدنيا من الانتشار أولاً لأن الكثير من اعمالي الشعرية والنثرية تم في وقت كنت اعمل فيها وكيلاً لوزارة الداخلية لمدة 25 عاماً ثانياً لأن المملكة بالرغم من التقدم المعرفي والمادي لا تتوفر فيها دور نشر مهنية قادرة على أستيعاب كافة المخرجات الفكرية والثقافية بالبلاد مع أحترامي للموجود فيها لأن لدور النشر في العالم دور محوري في تقديم وإبراز الاعمال الأدبية والفكرية للشعوب بينما الموجود لدينا مجرد مطابع ومكتباب , وكما ذكرت فأنه بسبب أنشغالي نشرت أغلب دواويني بواسطة الأندية الأدبية كنادي الطائف ولهذا لم تنشر بما يكفي ودائماً ما يسألني الناس عن دواويني مثلاً , الأمر الذي دفعني لأخراج كل اعمالي بموقع شامل يستطيع كل مهتم بشئ من انتاجي أن يطلع على مايريد ويسترجع ماشاء بدون الحاجة بالبحث بالمكتبات أو سؤالي شخصياً لمن يعرفني .        ',
        content1:`في هذا الموقع : تسعة دواوين هي "المداد , وشوم على جدار الوقت , مد والشاطئ أنت قصائد راعفه , فجر أنت لا تغب , غربة , نقطة في تضاريس الوطن , وثلاثة دواوين مترجمة" "هجرة قمر بالفرنسية , خيام القبيلة بالانجليزية , وذات صبح بالتركية" وفي الموقع الكثير من محاضراتي المسجلة والمكتوبة ولقاءاتي الصحفية وكتبي "التنمية وعربة الكرو , الرؤية والطبقة الوسطى" ورسالتي الماجستير "إدارة التنمية بالمملكة العربية السعودية " والدكتوراة " البيروقراطية والمجتمع السعودي " أتطلع إلى أن يحقق الموقع الهدف من أنشائه وأن يجد المتابع لمواده مايستحق عنده في المتابعة.`,
        content2:'والله من وراء القصد'
    }

}


function Home() {
    const  {lang} = React.useContext(LanguageContext)

    return (
        <div className="container">
            <div className="row pb-4 d-flex">
                <div className={"col-lg-7 col-md-12 col-sm-12 col-12 first-col "+(lang==="en"?"ff-bahij pl-0 ":"lang-ff pr-0")}>
                    <div className="d-flex py-2">
                        <img alt="brandImages" className="m-0" src={Asset_4} width="50" height="50" />
                        <h2 className={(lang==="en"?"ff-myriad-thin title py-2":"lang-ff title_ar py-2")}>{home[lang].title}</h2>
                    </div>
                    <p className={lang==="en"?"pl-5 pb-4 pr-1":"pr-5 pb-4 text-right"}>
                    {home[lang].content}              
                      <br />
                        <br />
                        {home[lang].content1}                       
                         <br />
                         <br />
                        <span className="float-right pr-1">{home[lang].content2} </span>
                    </p>
                </div>
                <div className={"col-lg-5 col-md-12 col-sm-12 col-12 second-col pt-4 "}>
                    <img alt="brandImages" src={Asset_5} className={"sm-image "+(lang=="en"?"pl-30":"")} />
                    {/* <div className="col-sm-12">
                        <p style={{fontWeight:600}} className={lang==="en"?"lang-ff img-content":" lang-ff img-content-ar"}>إبراهيم بن محمد بن  علي العواجي(۱۹٤۱) شاعر سعودي</p>
                        <img className={lang==="en"?"img-trademark":"img-trademark-ar"} alt="brandImages" src={Asset_3} />
                    </div> */}
                </div>
            </div>
            <div className="pt-4">
                <h1 className="text-center p-2 lang-ff" style={{ fontSize: "3rem" }}>مختارات الدواوين</h1>
                <div className="row justify-content-center">
                    {
                        [Book_1, Book_2, Book_3, Book_4].map((ele, i) => {
                            return <div key={ele} className="col-lg-4 col-md-4 col-sm-12 pt-4 text-center">
                                <img alt="brandImages" src={ele} width="150" className="img-border" />
                                <p className="pt-2"><span className="book-badder lang-ff" style={{fontWeight:600}}>لرؤية محتوى الكتاب</span></p>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

    )
}

export default Home