import React from 'react';
import Asset_3 from '../../assets/Asset 3.svg';
import '../lectures/lectures.css'
import { booksList }  from '../books/books-list';
import { LanguageContext } from '../../App';

const journalism ={
    en:{
        header:'Journalism',
        title:'Post Title',
        category:'Category',
        view:'View',
        viewButton:'View'
    },
    ar:{
        header:'الصحافة',
        title:'العنوان',
        category:'التصنيف',
        view:'عرض',
        viewButton:'لرؤية محتوى'
    }
}

function Journalism(props) {
    const  {lang} = React.useContext(LanguageContext)
    const list = booksList['Journalism'];
    const prefix = 58
    return (
        <div className="mt-5 topview">
            <p className="text-center ff-bahij title-fs">{journalism[lang].header}</p>
            <div className="container container-fix bg mt-5" style={{ "position": "relative" }}>
                <div className="row align-items-center">
                <div className={"col-3 col-md-2 pt-4  "+(lang==="en"?"text-left ff-myriad-thin pl2  ":"text-center lang-ff")}>{journalism[lang].category}</div>
                    <div className={"col-6 col-md-7 pt-4   fs-title "+(lang==="en"?"text-left ff-bahij":"text-right lang-ff")}>{journalism[lang].title}</div>
                    <div className={"col-2 col-md-2 text-center pt-4 fs-view "+(lang==="en"?"ff-myriad-thin":"lang-ff")}>{journalism[lang].view}</div>

                    <hr width="100%;" color="#c9c9c7" size="10" />
                    {
                        list.map((ele, i) => {
                            return(
                                 <>
                                     <div className={"col-3 col-md-2 py-3 my-auto "+(lang==="en"?"text-left ff-myriad-thin pl2 sm-title-poem ":"text-center lang-ff")}>{journalism[lang].header}</div>
                                    <div className={"col-6 col-md-7 pt-2 my-auto   fs-title-content "+(lang==="en"?"text-left  ":"text-right  ")+ele.font}>{ele.text}</div>
                                    <div className="col-2 col-2 py-3 px-0 text-center">
                                    <button className={"btn btn-change "+(lang==="en"?"ff-myriad-thin":"lang-ff")} onClick={()=>props.history.push(`/journalism/view/${prefix+ i}`)}>{journalism[lang].viewButton}</button>
                                 </div>
                                 </>
                            )
                        })
                    }


                </div>
                <img src={Asset_3} alt="brandImages" className="trademark" />


            </div>
        </div>
    )
}

export default Journalism;