import React, { useEffect, useState } from 'react';
import './header.css'
import { Link } from "react-router-dom";
import Asset_3 from '../../assets/Asset 3.svg';
import fill_down from '../../assets/Fill.svg';
import fill_up from '../../assets/Fill2.svg';
import { useLocation } from 'react-router-dom';
import {LanguageContext}  from '../../App'

const header = {
    en: {
        title: 'Ibrahim bin Mohammed Al - Awaji',
        language: 'عربي',
        navbar: {
            home: 'Home',
            books: 'Books',
            subBooks: ['Formal Poems', 'Traditional Poems', 'Translated Poems', 'Development Poems'],
            journalism: 'Journalism',
            lectures: 'Lectures',
            audioPoems: 'Audio Poems',
            interviews: 'Interviews'
        }
    },
    ar: {
        title: 'إبراهيم بن محمد بن علي العواجي',
        language: 'English',
        navbar: {
            home: 'الصفحة الرئيسية',
            books: 'كتب',
            subBooks: ['الشعر الفصيح', 'الشعر الشعبي', 'الشعر المترجم', 'كتب في التنمية'],
            journalism: 'الصحافة',
            lectures: 'محاضرات',
            audioPoems: 'قصائد صوتية',
            interviews: 'المقابلات'
        }
    }

}


function Header(props) {
const  {lang,changeLanguage} = React.useContext(LanguageContext)
const [arrow, setArrow] = React.useState(fill_down);
    const openNav = () => {
        document.getElementById("mySidenav").style.width = "250px";
    }

    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
    }

    const location = useLocation();

    const [pathname, setPathname] = useState('/')
    const toggleArrow = ()=>{
        if(arrow===fill_up){
            setArrow(fill_down)
        }else{
            setArrow(fill_up)
        }
    }
    useEffect(() => {
        setPathname(location.pathname)
    }, [location.pathname])

    return (
            <div className="container-top">
                <div className="d-flex align-items-center">
                    <div className={"d-block d-md-none "+(lang==="en"?"ml-3":"mr-3")} onClick={() => openNav()} >
                        <span className="fas fa-bar ">&#9776;</span>
                    </div>
                    <h3 className={"logo-title m-auto " + (lang === "en" ? "header-ff logo-title m-auto " : "ff-muslimah logo-title m-auto fs-2")}>{header[lang].title}</h3>
                </div>

                <div className="col-12 d-none d-md-block">
                    <span className={(lang === "en" ? "lang-ff fs-20" : "ff-myriad")} style={{ marginLeft: "4rem", "cursor":"pointer" }} onClick={()=>changeLanguage()} >{header[lang].language}</span>
                </div>
                <nav className="navbar navbar-expand-md navbar-light">
                    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                        <ul className={"navbar-nav " + (lang === "en" ? "ff-bahij " : "lang-ff p-0")}>
                            <li className="mx-2 nav-item">
                                <Link to="/" className={`nav-link ${pathname === '/' ? 'active' : ''}`}>
                                    {header[lang].navbar.home}
                                </Link>
                            </li>

                            <li className="mx-2 nav-item dropdown">
                                <Link to="#" className={`nav-link ${!(pathname.match('/books')) ? '' : 'active'}`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{header[lang].navbar.books}
                                </Link>

                                <div className="dropdown-menu bg-color" aria-labelledby="navbarDropdown" >
                                    <Link className="dropdown-item" to="/books/Formal Poems">{header[lang].navbar.subBooks[0]}</Link>
                                    <Link className="dropdown-item" to="/books/Traditional Poems">{header[lang].navbar.subBooks[1]}</Link>
                                    <Link className="dropdown-item" to="/books/Translated Poems">{header[lang].navbar.subBooks[2]}</Link>
                                    <Link className="dropdown-item" to="/books/Development Books">{header[lang].navbar.subBooks[3]}</Link>
                                </div>
                            </li>
                            <li className="mx-2 nav-item">
                                <Link to="/journalism" className={`nav-link  ${((pathname === '/journalism')||(pathname.match('/journalism')))  ? 'active' : ''}`} >{header[lang].navbar.journalism}
                                </Link>
                            </li>
                            <li className="mx-2 nav-item">
                                <Link to="/lectures" className={`nav-link  ${((pathname === '/lectures')||(pathname.match('/lectures')))  ? 'active' : ''}`} >{header[lang].navbar.lectures}
                                </Link>
                            </li>
                            <li className="mx-2 nav-item">
                                <Link to="/audio-poems" className={`nav-link  ${((pathname === '/audio-poems')||(pathname.match('/audio-poems')))  ? 'active' : ''}`}>{header[lang].navbar.audioPoems}
                                </Link>
                            </li>
                            <li className="mx-2 nav-item">
                                <Link to="/interviews" className={`nav-link  ${((pathname === '/interviews')||(pathname.match('/interviews'))) ? 'active' : ''}`}>{header[lang].navbar.interviews}
                                </Link>
                            </li>
                        </ul>

                    </div>
                </nav>

                <div id="mySidenav" className={"sidenav " + (lang === "en" ? "ff-bahij " : "lang-ff")}>
                    <div>
                        <img src={Asset_3} alt="brandImages" style={{ width: "34%" }} />
                        <span className="float-right" style={{ fontSize: "37px", marginRight: "1rem" }} onClick={() => closeNav()}>&times;</span>
                    </div>
                    <div className={lang==="en"?"styled-select":"styled-select "}>
                        <select className="ff-bahij" value={lang} onChange={()=>changeLanguage()}>
                            <option value="ar">Arabic</option>
                            <option value="en">English</option>
                        </select>
                        <span className="fa fa-sort-desc"></span>
                    </div>
                    <div className="bbl">
                        <Link to="/" className={`nav-link ${pathname === '/' ? 'active' : ''}`}  onClick={() => closeNav()} >{header[lang].navbar.home}</Link>
                    </div>
                    <div className="bbl">
                        <Link to="#"  className={`nav-link ${!(pathname.match('/books')) ? '' : 'active'}`} onClick={()=>toggleArrow()} data-toggle="collapse" data-target="#collapseOne">{header[lang].navbar.books} <span className="float-right"><img src={arrow} alt="arrow"></img></span>
                        </Link>

                        <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordion" >
                            <Link  className={`nav-link ${!(pathname.match('/books/Formal Poems')) ? '' : 'active'} pl-1`} to="/books/Formal Poems"  onClick={() => closeNav()}>{header[lang].navbar.subBooks[0]}</Link>
                            <Link  className={`nav-link ${!(pathname.match('/books/Traditional Poems')) ? '' : 'active'} pl-1`} to="/books/Traditional Poems"  onClick={() => closeNav()}>{header[lang].navbar.subBooks[1]}</Link>
                            <Link  className={`nav-link ${!(pathname.match('/books/Translated Poems')) ? '' : 'active'} pl-1`} to="/books/Translated Poems"  onClick={() => closeNav()}>{header[lang].navbar.subBooks[2]}</Link>
                            <Link  className={`nav-link ${!(pathname.match('/books/Development Books')) ? '' : 'active'} pl-1`} to="/books/Development Books"  onClick={() => closeNav()}>{header[lang].navbar.subBooks[3]}</Link>
                        </div>
                    </div>
                    <div className="bbl">
                        <Link className={`nav-link ${((pathname === '/journalism') ||(pathname.match('/journalism'))) ? 'active' : ''}`} to="/journalism" onClick={() => closeNav()}>{header[lang].navbar.journalism}</Link>
                    </div>
                    <div className="bbl">
                        <Link className={`nav-link ${((pathname === '/lectures')||(pathname.match('/lectures'))) ? 'active' : ''}`} to="/lectures" onClick={() => closeNav()}>{header[lang].navbar.lectures}</Link>
                    </div>
                    <div className="bbl">
                        <Link className={`nav-link ${pathname === '/audio-poems' ? 'active' : ''}`} to="/audio-poems" onClick={() => closeNav()}>{header[lang].navbar.audioPoems}</Link>
                    </div>
                    <div className="bbl">
                        <Link className={`nav-link ${((pathname === '/interviews')||(pathname.match('/interviews'))) ? 'active' : ''}`} to="/interviews" onClick={() => closeNav()}>{header[lang].navbar.interviews}</Link>
                    </div>
                </div>
            </div>
    )
}

export default Header;