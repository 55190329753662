import './App.css';
import Header from './components/header/header';
import Home from './components/home/home';
import Books from './components/books/books';
import Journalism from './components/journalism/journalism';
import Lectures from './components/lectures/lectures';
import AudioPoems from './components/audio-peom/audio-peom';
import Interviews from './components/interviews/interviews';
import Footer from './components/footer/footer';
import { Switch, Route } from 'react-router';
import Viewer from './components/view-pdf-video/view-pdf-video';
import React, { useEffect, useState } from 'react'

export const LanguageContext = React.createContext('en')

export function App() {
  const [lang, setLang] = useState(sessionStorage.getItem('ln') || 'en')


  useEffect(() => {
    sessionStorage.setItem('ln', lang)
    document.getElementsByTagName('BODY')[0].setAttribute('lang', lang)
    document.getElementsByTagName('BODY')[0].setAttribute('dir', lang === 'en' ? 'ltr' : 'rtl')

  }, [lang])
  const changeLanguage = () => {
    let ln = 'en'
    setLang(lang === ln ? 'ar' : 'en');
  }

  return (
    <div>

      <LanguageContext.Provider value={{ lang, changeLanguage }}>

        <Header></Header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/books/:title" component={Books}></Route>
          <Route exact path="/journalism" component={Journalism}></Route>
          <Route exact path="/lectures" component={Lectures}></Route>
          <Route exact path="/audio-poems" component={AudioPoems}></Route>
          <Route exact path="/interviews" component={Interviews}></Route>
          <Route exact path="/:type/view/:id" component={Viewer}></Route>
        </Switch>
        <Footer></Footer>
      </LanguageContext.Provider>

    </div>
  );
};