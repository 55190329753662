import React, { useEffect, useState } from 'react';
import Asset_3 from '../../assets/Asset 3.svg';
import '../lectures/lectures.css'
import { booksList }  from './books-list';
import './book.css'
import { LanguageContext } from '../../App';

const book ={
    en:{
        title:'Post Title',
        category:'Category',
        view:'View',
        viewButton:'View'
    },
    ar:{
        title:'العنوان',
        category:'التصنيف',
        view:'عرض',
        viewButton:'لرؤية محتوى الكتاب'
    }
}

const titles = {
    Formal_Poems:{en:'Formal Poems',ar:'الشعر الفصيح'},
    Traditional_Poems:{en:'Traditional Poems',ar:'الشعر الشعبي'},
    Translated_Poems:{en:'Translated Poems',ar:'الشعر المترجم'},
    Development_Books:{en:'Development Poems',ar:'كتب في التنمية'},
}
const Htitles = {
    Formal_Poems:{en:['Formal', 'Poems'],ar:['الشعر',' الفصيح']},
    Traditional_Poems:{en:['Traditional' ,'Poems'],ar:['الشعر',' الشعبي']},
    Translated_Poems:{en:['Translated', 'Poems'],ar:['الشعر',' المترجم']},
    Development_Books:{en:['Development',' Poems'],ar:['كتب في',' التنمية']},
}
function Books(props) {
    const  {lang} = React.useContext(LanguageContext)

    const title = props.match.params.title || 'Books';
    const prefixIndex = {
        Formal_Poems: 28,
        Translated_Poems: 41,
        Development_Books: 44
    }
    const selectedBooks = title.replace(' ', '_')
    const [ list, setList ] = useState([]);
    const [ prefix, setPrefix ] = useState([]);

    useEffect(() => {
        setList(booksList[selectedBooks])
        setPrefix(prefixIndex[selectedBooks])
    }, [selectedBooks])

    return (
        <div className="mt-5 topview">
            <p className={"text-center ff-bahij title-fs"}>{titles[selectedBooks][lang]}</p>
            <div className="container container-fix bg mt-5" style={{ "position": "relative" }}>
                <div className="row align-items-center">
                    <div className={"col-3 col-md-2 pt-4  "+(lang==="en"?"text-left ff-myriad-thin pl2 ":"text-center lang-ff")}>{book[lang].category}</div>
                    <div className={"col-6 col-md-7 pt-4   fs-title "+(lang==="en"?"text-left ff-bahij":"text-right lang-ff")}>{book[lang].title}</div>
                    <div className={"col-2 col-md-2 text-center pt-4 ff-myriad-thin fs-view "+(lang==="en"?"ff-myriad-thin":"lang-ff")}>{book[lang].view}</div>

                    <hr width="100%;" color="#c9c9c7" size="10" />
                    {
                        list.map((ele, i) => {
                            return(
                                 <>
                                     <div className={"col-3 col-md-2 py-3 my-auto sm-title-poem  "+(lang==="en"?"text-left ff-myriad-thin pl2":"text-center lang-ff")}>{Htitles[selectedBooks][lang][0]}<br />{Htitles[selectedBooks][lang][1]}</div>
                                    <div className={"col-6 col-md-7 pt-2 my-auto fs-title-content "+(lang==="en"?"text-left ":"text-right ")+ ele.font}>{ele.text}</div>
                                    <div className={"col-2 col-2 py-3 px-0 text-center "}>
                                    <button className={"btn btn-change "+(lang==="en"?"ff-myriad-thin":"lang-ff")} onClick={()=>props.history.push(`/books/view/${prefix+ i + 2}`)}>{book[lang].viewButton}</button>
                                 </div>
                                 </>
                            )
                        })
                    }

                </div>
                <img src={Asset_3} alt="brandImages" className="trademark" />
            </div>
        </div>
    )
}

export default Books;