import React from 'react';
import './audio-poem.css'
import next from '../../assets/next.svg'
import play from '../../assets/play.svg'
import previous from '../../assets/previous.svg'
import speaker from '../../assets/speaker.svg'
import pause from '../../assets/pause.svg'
import mute from '../../assets/mute.svg'
import settings from '../../assets/settings.svg'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Asset_3 from '../../assets/Asset 3.svg';
import { LanguageContext } from '../../App';
import Audio1 from '../../assets/audio/01 Track 1.mp3';
import Audio2 from '../../assets/audio/02 Track 2.mp3';
import Audio3 from '../../assets/audio/03 Track 3.mp3';
import Audio4 from '../../assets/audio/04 Track 4.mp3';
import Audio5 from '../../assets/audio/05 Track 5.mp3';
import Audio6 from '../../assets/audio/06 Track 6.mp3';
import Audio7 from '../../assets/audio/07 Track 7.mp3';
import Audio8 from '../../assets/audio/08 Track 8.mp3';
import Audio9 from '../../assets/audio/09 Track 9.mp3';
import Audio10 from '../../assets/audio/10 Track 10.mp3';
import Audio11 from '../../assets/audio/11 Track 11.mp3';
import Audio12 from '../../assets/audio/12 Track 12.mp3';
import Audio13 from '../../assets/audio/13 Track 13.mp3';
import Audio14 from '../../assets/audio/14 Track 14.mp3';
import Audio15 from '../../assets/audio/15 Track 15.mp3';
import Audio16 from '../../assets/audio/16 Track 16.mp3';
import Audio17 from '../../assets/audio/17 Track 17.mp3';
import Audio18 from '../../assets/audio/18 Track 18.mp3';
import Audio19 from '../../assets/audio/19 Track 19.mp3';
import Audio20 from '../../assets/audio/20 Track 20.mp3';
import Audio21 from '../../assets/audio/21 Track 21.mp3';
import Audio22 from '../../assets/audio/22 Track 22.mp3';
import Audio23 from '../../assets/audio/23 Track 23.mp3';
import Audio24 from '../../assets/audio/24 Track 24.mp3';
import Audio25 from '../../assets/audio/25 Track 25.mp3';
import Audio26 from '../../assets/audio/26 Track 26.mp3';
import Audio27 from '../../assets/audio/27 Track 27.mp3';
import Audio28 from '../../assets/audio/28 Track 28.mp3';
import Audio29 from '../../assets/audio/29 Track 29.mp3';
import Audio30 from '../../assets/audio/1.mp3';
import Audio31 from '../../assets/audio/2.mp3';
import Audio32 from '../../assets/audio/3.mp3';
import Audio33 from '../../assets/audio/4.mp3';
import Audio34 from '../../assets/audio/5.mp3';
import Audio35 from '../../assets/audio/6.mp3';
import Audio36 from '../../assets/audio/7.mp3';
import Audio37 from '../../assets/audio/8.mp3';
import Audio38 from '../../assets/audio/9.mp3';
import Audio39 from '../../assets/audio/10.mp3';
import Audio40 from '../../assets/audio/11.mp3';
import Audio41 from '../../assets/audio/12.mp3';
import Audio42 from '../../assets/audio/13.mp3';
import Audio43 from '../../assets/audio/14.mp3';
import Audio44 from '../../assets/audio/15.mp3';
import Audio45 from '../../assets/audio/16.mp3';
import Audio46 from '../../assets/audio/17.mp3';
import Audio47 from '../../assets/audio/18.mp3';
import Audio48 from '../../assets/audio/19.mp3';
import Audio49 from '../../assets/audio/20.mp3';
import Audio50 from '../../assets/audio/21.mp3';
import Audio51 from '../../assets/audio/22.mp3';
import Audio52 from '../../assets/audio/23.mp3';
import Audio53 from '../../assets/audio/24.mp3';
import Audio54 from '../../assets/audio/25.mp3';
import Audio55 from '../../assets/audio/26.mp3';
import Audio56 from '../../assets/audio/27.mp3';
import Audio57 from '../../assets/audio/28.mp3';
import Audio58 from '../../assets/audio/29.mp3';
import Audio59 from '../../assets/audio/29.mp3';
import Audio60 from '../../assets/audio/61.mp3';
import Audio61 from '../../assets/audio/62.mp3';
import Audio62 from '../../assets/audio/64.mp3';
import Audio63 from '../../assets/audio/65.mp3';
import Audio64 from '../../assets/audio/67.mp3';
import Audio65 from '../../assets/audio/68.mp3';



const audios = [
    Audio1,
    Audio2,
    Audio3,
    Audio4,
    Audio5,
    Audio6,
    Audio7,
    Audio8,
    Audio9,
    Audio10,
    Audio11,
    Audio12,
    Audio13,
    Audio14,
    Audio15,
    Audio16,
    Audio17,
    Audio18,
    Audio19,
    Audio20,
    Audio21,
    Audio22,
    Audio23,
    Audio24,
    Audio25,
    Audio26,
    Audio27,
    Audio28,
    Audio29,
    Audio30,
    Audio31,
    Audio32,
    Audio33,
    Audio34,
    Audio35,
    Audio36,
    Audio37,
    Audio38,
    Audio39,
    Audio40,
    Audio41,
    Audio42,
    Audio43,
    Audio44,
    Audio45,
    Audio46,
    Audio47,
    Audio48,
    Audio49,
    Audio50,
    Audio51,
    Audio52,
    Audio53,
    Audio54,
    Audio55,
    Audio56,
    Audio57,
    Audio58,
    Audio59,
    Audio60,
    Audio61,
    Audio62,
    Audio63,
    Audio64,
    Audio65,
]


const audio = {
    en: {
        header: 'Audio Poems',
        list: 'Audio List',
        play: 'Play Audio',
        translated: '(Translated Poems)'
    },
    ar: {
        header: 'قصائد صوتية',
        play: 'تشغيل الصوت',
        list: 'التصنيف',
        translated: '(الشعر الشعبي)'
    }
}

function AudioPeom() {
    const { lang } = React.useContext(LanguageContext)

    return (

        <div className="mt-5 topview">
            <p className={"text-center title-fs " + (lang === 'en' ? "ff-bahij" : "lang-ff")}>{audio[lang].header}</p>
            <div className="container container-fix bg mt-5" style={{ "position": "relative" }}>
                <div className="row align-items-center">
                    <div className={"col-4 pt-4  fs-title " + (lang === 'en' ? "ff-bahij pl2" : "lang-ff pr-5 text-right")}>{audio[lang].list}</div>
                    <div className={"col-8  pt-4 pr-5  fs-title " + (lang === 'en' ? "ff-bahij text-right" : "lang-ff text-left")}>{audio[lang].play}</div>
                    <hr width="100%;" color="#c9c9c7" size="10" />
                    {audios.map((url, i) => (
                        <React.Fragment key={i}>
                            <div className={"col-4 py-3  my-auto  fs-title-audio-content mt-2 " + (lang === 'en' ? "pl2 ff-myriad-thin" : "lang-ff pr-5 text-right")}>{audio[lang].translated}</div>
                            <div className="col-8 py-3 pr-4  my-auto mt-2">


                                <AudioPlayer className={"player pr-4 " + (lang == "ar" ? "ar-player" : '')} style={{ direction: "ltr" }}
                                    preload="none"
                                    src={url}
                                    onPlay={e => console.log("onPlay")}
                                    customIcons={{
                                        pause: <img src={pause} alt="brandImages" />,
                                        forward: <img src={next} alt="brandImages" />,
                                        rewind: <img src={previous} alt="brandImages" />,
                                        volume: <img src={speaker} alt="brandImages" />,
                                        volumeMute: <img src={mute} alt="brandImages" />,
                                        // settings: <img src={settings} alt="brandImages" />,
                                        play: <img src={play} alt="brandImages" />,

                                    }}
                                // customAdditionalControls={
                                //     [
                                //         // <a href={url} download>
                                //         // <img src={settings} className="settings" alt="brandImages" />
                                //         // </a>
                                //         <div className="dropdown">
                                //         <div  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                //         <img src={settings} className="settings" alt="brandImages" />
                                //         </div>
                                //         <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                //           <a className="dropdown-item" href={url} download="audio.mp3">Do</a>

                                //         </div>
                                //       </div>
                                //         ]}

                                />
                                <div className={"download-container " + (lang == "ar" ? "download-container-ar" : "")}>
                                    <div className="dropdown">
                                        <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={settings} className="settings" alt="brandImages" />
                                        </div>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a className="dropdown-item" href={url} download="audio.mp3">Download</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                    }
                </div>
                <img src={Asset_3} alt="brandImages" className="trademark" />

            </div>
        </div>
    )
}

export default AudioPeom;