import React from 'react';
import './footer.css'
import Asset_3 from '../../assets/Asset 3.svg';
import fb from '../../assets/fb.svg';
import insta from '../../assets/insta.svg';
import whatsapp from '../../assets/whatsapp.svg';
import twitter from '../../assets/twitter.svg';

function Footer(props) {
    console.log(props)
    return (
        <div className="py-4 footer-bg mt-5 ff-myriad-thin">
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4 pt-2">
                    <img className="img-trademark-footer" alt="brandImages" src={Asset_3} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-8 col-8 align-self-center">
                    <h4 className="pl-1">Search Keywords</h4>
                    <input className="form-control mr-sm-2 fm-control" type="search" placeholder="Search" aria-label="Search" />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 pt-2  d-md-block">
                    <span className="border-bt">
                        Recent Posts
                    </span>
                    <address className="pt-2">
                        <a className="footer" href="/books/view/41">
                            Paper cutting.pdf
                        </a><br />
                        <a className="footer" href="/books/view/40">
                            Paper cut 3
                        </a><br />
                        <a className="footer" href="/books/view/39">
                            Book News Paper cuttings
                        </a><br />
                        <a className="footer" href="/books/view/57">
                            A Statment on the human resources planning
                        </a>
                    </address>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 col-12 align-self-center pt-4">
                    <h4 className="text-center">Follow Us</h4>
                    <div className="row d-flex justify-content-center">
                        <div >
                            <a href="https://www.facebook.com/sharer.php?t=Ibrahim%20bin%20Mohammed%20Al%20-%20Awaji&u=http%3A%2F%2Fwww.ibrahimalawaji.com%2FHome%2FViewPost%2F77" target="_blank">
                            <img src={fb} alt="brandImages" className="img-media"></img>
                            </a>
                        </div>
                        <div >
                            <a href="https://web.whatsapp.com/send?text=http%3A%2F%2Fwww.ibrahimalawaji.com%2FHome%2FViewPost%2F77">
                            <img src={whatsapp} alt="brandImages" className="img-media"></img>
                            </a>
                        </div>
                        <div >
                            <a href="" target="_blank">
                            <img src={insta} alt="brandImages" className="img-media"></img>
                            </a>
                        </div>
                        <div >
                            <a href="https://twitter.com/intent/tweet?text=Ibrahim%20bin%20Mohammed%20Al%20-%20Awaji&url=http%3A%2F%2Fwww.ibrahimalawaji.com%2FHome%2FViewPost%2F77" target="_blank">
                            <img src={twitter} alt="brandImages" className="img-media"></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="p-1">
                    <hr className="hr-line" />
                </div>
                <div>
                    <h6 className="text-center m-0">All RIGHTS RESERVED TO IBRAHIM BIN MOHAMMED AL AWAJI</h6>
                </div>
            </div>
        </div>

    )
}

export default Footer;