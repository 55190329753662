import React from 'react';
import './view-pdf-video.css'
import Asset_3 from '../../assets/Asset 3.svg';
import { Document, Page, pdfjs } from 'react-pdf';
import Book1 from '../../assets/books/Book1.pdf';
import Book2 from '../../assets/books/Book2.pdf';
import Book5 from '../../assets/books/Book5.pdf';
import Book6 from '../../assets/books/Book6.pdf';
import Book7 from '../../assets/books/Book7.pdf';
import Book8 from '../../assets/books/Book8.pdf';
import Book9 from '../../assets/books/Book9.pdf';
import Book11 from '../../assets/books/Book11.pdf';
import Book_News_Paper_Cuttings from '../../assets/books/Book News Paper Cuttings.pdf';
import paper_cut_3 from '../../assets/books/paper cut 3.pdf';
import Paper_cutting from '../../assets/books/Paper cutting.pdf';
import Poems from '../../assets/books/Poems.pdf';
import Book_5 from '../../assets/books/Book_5.pdf';
import Book_9 from '../../assets/books/Book_9.pdf';
import Book3 from '../../assets/books/book3.pdf';
import Book10 from '../../assets/books/Book10.pdf';
import Book12 from '../../assets/books/Book12.pdf';
import bureaucracy_and_society_in_Saudi from '../../assets/books/bureaucracy and society in Saudi.pdf';
import File4 from '../../assets/books/File4.pdf';
import File5 from '../../assets/books/File5.pdf';
import File8 from '../../assets/books/File8.pdf';
import File9 from '../../assets/books/File9.pdf';
import Role_of_research_in_policy_making from '../../assets/books/Role of research in policy making.pdf';
import File10 from '../../assets/books/الرؤية والطبقة الوسطى.pdf';
import File11 from '../../assets/books/قراءات في الاعمال الكاملة.pdf';
import A_Statment_on_the_human_resources_planning from '../../assets/books/A Statment on the human resources planning.pdf';
import file_00001 from '../../assets/books/file_00001 (2).pdf';
import file_00002 from '../../assets/books/file_00001 (3).pdf';
import file_00003 from '../../assets/books/file_00001 (4).pdf';
import file_00004 from '../../assets/books/file_00001 (5).pdf';
import file_00005 from '../../assets/books/file_00001 (6).pdf';
import file_00006 from '../../assets/books/file_00001 (7).pdf';
import file_00007 from '../../assets/books/file_00001 (8).pdf';
import file_00008 from '../../assets/books/file_00001 (9).pdf';
import file_00009 from '../../assets/books/file_00001.pdf';
import file_00010 from '../../assets/books/file_00002 (2).pdf';
import file_00011 from '../../assets/books/file_00002.pdf';
import file_00012 from '../../assets/books/file_00003 (2).pdf';
import file_00013 from '../../assets/books/file_00003.pdf';
import File3 from '../../assets/books/File3.pdf';
import File7 from '../../assets/books/File7.pdf';
import video_1 from '../../assets/videos/1_1.mp4';
import video_2 from '../../assets/videos/2 00_00_00-01_00_00.mp4';
import video_3 from '../../assets/videos/4.mp4';
import video_4 from '../../assets/videos/7.mp4';
import video_5 from '../../assets/videos/B17.mp4';
import video_6 from '../../assets/videos/B18 00_00_00-00_41_00.mp4';
import video_7 from '../../assets/videos/B21.mp4';
import video_8 from '../../assets/videos/3 00_00_00-01_00_00.mp4';
import video_9 from '../../assets/videos/5 00_00_00-02_21_00.mp4';
import video_10 from '../../assets/videos/6 00_00_00-01_15_00.mp4';
import video_11 from '../../assets/videos/8.mp4';
import video_12 from '../../assets/videos/10 00_00_00-00_58_00.mp4';
import video_13 from '../../assets/videos/11 00_00_00-01_33_00.mp4';
import video_14 from '../../assets/videos/13 00_00_00-01_41_00.mp4';
import video_15 from '../../assets/videos/14.mp4';
import video_16 from '../../assets/videos/B16 00_00_00-01_36_00.mp4.mp4';
import video_17 from '../../assets/videos/B20.mp4';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const files = [

    {
        id: 1,
        type: 'youtube',
        topic: 'Interview',
        url: "https://www.youtube.com/embed/Dj-WmSI45N8",
        title:'برنامج وينك. الشاعر والأديب إبراهيم العواجي وكيل وزارة الداخليه سابقا',
        ln:'ar'
    },
    {
        id: 2,
        type: 'youtube',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/QMBaneY9qWo',
        title:'مقابلة إبراهيم العواجي مع محمد رضا نصرالله في برنامج (هكذا تكلموا) عام 2006م',
        ln:'ar'
    },
    {
        id: 3,
        type: 'youtube',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/RV3t4Zd-Sx0',
        title:'زوايا مع د. الزير " شخصية وطن " مع د. ابراهيم العواجي',
        ln:'ar'
    },
    {
        id: 4,
        type: 'pdf',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/ANnNNewCXPQ',
        title:'اضاءات : ابراهيم العواجي',
        ln:'ar'
    },
    {
        id: 5,
        type: 'pdf',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/epZgE6oL3EM',
        title:'برنامج رحلة الكلمة مع د.إبراهيم العواجي',
        ln:'ar'
    },
    {
        id: 6,
        type: 'pdf',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/t37kBfN_H2k',
        title:'الوجه الآخر الدكتور ابراهيم بن محمد العواجي',
        ln:'ar'
    },
    {
        id: 7,
        type: 'pdf',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/5NEIiQ9VAh4',
        title:'كلنا مسئول عن الوطن | الدكتور/ إبراهيم العواجي | في مجلس الوائلية بالدمام',
        ln:'ar'
    },
    {
        id: 8,
        type: 'pdf',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/gL01uXSbnKg',
        title:'د. ابراهيم العواجي في الرسالة اليوم في قناة الرسالة',
        ln:'ar'
    },
    {
        id: 9,
        type: 'youtube',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/LGLagbzmYMM',
        title:'الشاعر ابراهيم العواجي يعلنها من أدبي مكة : الشعر لم يعد ديواناً للعرب',
        ln:'ar'
    },
    {
        id: 10,
        type: 'youtube',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/NogKsK6TzDY',
        title:'أمسية شعرية أقيمت بنادي مكة الثقافي الأدبي مساء الأربعاء:12/3/1437',
        ln:'ar'
    },
    {
        id: 11,
        type: 'youtube',
        topic: 'Interview',
        url: 'http://www.youtube.com/embed/2BJQ8NR4qXY',
        title:'أمسية شعرية',
        ln:'ar'
    },
    {
        id: 12,
        type: 'video',
        topic: 'Interview',
        url: video_1,
        title:'مقابلة شخصية - مركز الملك فهد الثقافي بالرياض',
        ln:'ar'
    },
    {
        id: 13,
        type: 'video',
        topic: 'Interview',
        url: video_2,
        title:'مقابلة شخصية',
        ln:'ar'
    },
    {
        id: 14,
        type: 'video',
        topic: 'Interview',
        url: video_3,
        title:'برنامج السهرة المفتوحة',
        ln:'ar'
    },
    {
        id: 15,
        type: 'video',
        topic: 'Interview',
        url: video_4,
        title:'امسية شعرية',
        ln:'ar'
    },
    {
        id: 16,
        type: 'video',
        topic: 'Interview',
        url: video_5,
        title:'مقابلة شخصية',
        ln:'ar'
    },
    {
        id: 17,
        type: 'video',
        topic: 'Interview',
        url: video_6,
        title:'لقاء مع طلبة معهد الضباط عام 1406 هـ',
        ln:'ar'
    },
    {
        id: 18,
        type: 'video',
        topic: 'Interview',
        url: video_7,
        title:'مقابلة شخصية',
        ln:'ar'
    }
    , {
        id: 19,
        type: 'video',
        topic: 'Lecture',
        url: video_8,
        title:'أمسية شعرية - نادي ابها الادبي',
        ln:'ar'
    }
    , {
        id: 20,
        type: 'video',
        topic: 'Lecture',
        url: video_9,
        title:'محاضرة 2',
        ln:'ar'
    }, {
        id: 21,
        type: 'video',
        topic: 'Lecture',
        url: video_10,
        title:'محاضرة 3',
        ln:'ar'
    }, {
        id: 22,
        type: 'video',
        topic: 'Lecture',
        url: video_11,
        title:'محاضرة 4',
        ln:'ar'
    }, {
        id: 23,
        type: 'video',
        topic: 'Lecture',
        url: video_12,
        title:'محاضرة 5',
        ln:'ar'
    }, {
        id: 24,
        type: 'video',
        topic: 'Lecture',
        url: video_13,
        title:'امسية شعرية - نادي المنطقة الشرقة الادبي',
        ln:'ar'
    }, {
        id: 25,
        type: 'video',
        topic: 'Lecture',
        url: video_14,
        title:'امسية شعرية',
        ln:'ar'
    }, {
        id: 26,
        type: 'video',
        topic: 'Lecture',
        url: video_15,
        title:'امسية شعرية',
        ln:'ar'
    },
    {
        id: 27,
        type: 'video',
        topic: 'Lecture',
        url: video_16,
        title:'محاضرة 6',
        ln:'ar'
    },
    {
        id: 28,
        type: 'video',
        topic: 'Lecture',
        url: video_17,
        title:'محاضرة 7',
        ln:'ar'
    },
    {
        id: 29,
        type: 'pdf',
        topic: 'Lecture',
        url: Book3,
        title:'محاضرة 7',
        ln:'ar'
    },
    {
        id: 30,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book1',
        url: Book1,
        ln:'en'
    },
    {
        id: 31,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book2',
        url: Book2,
        ln:'en'
    },
    {
        id: 32,
        type: 'pdf',
        topic: 'Formal Poems',
        title: '',
        url: '',
        ln:'en'
    },
    {
        id: 33,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book5',
        url: Book5,
        ln:'en'
    },
    {
        id: 34,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book6',
        url: Book6,
        ln:'en'
    },
    {
        id: 35,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book7',
        url: Book7,
        ln:'en'
    },
    {
        id: 36,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book8',
        url: Book8,
        ln:'en'
    },
    {
        id: 37,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book9',
        url: Book9,
        ln:'en'
    },
    {
        id: 38,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book11',
        url: Book11,
        ln:'en'
    },
    {
        id: 39,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Book News Paper Cuttings',
        url: Book_News_Paper_Cuttings,
        ln:'en'
    },
    {
        id: 40,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'paper cut 3',
        url: paper_cut_3,
        ln:'en'
    },
    {
        id: 41,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Paper cutting',
        url: Paper_cutting,
        ln:'en'
    },
    {
        id: 42,
        type: 'pdf',
        topic: 'Formal Poems',
        title: 'Poems',
        url: Poems,
        ln:'en'
    },
    {
        id: 43,
        type: 'pdf',
        topic: 'Translated Poems',
        title: 'Book5',
        url: Book_5,
        ln:'en'
    },
    {
        id: 44,
        type: 'pdf',
        topic: 'Translated Poems',
        title: 'Book9',
        url: Book_9,
        ln:'en'
    },
    {
        id: 45,
        type: 'pdf',
        topic: 'Translated Poems',
        title: 'Book3',
        url: Book3,
        ln:'en'
    },
    {
        id: 46,
        type: 'pdf',
        topic: 'Development Books',
        title: 'Book10',
        url: Book10,
        ln:'en'
    },
    {
        id: 47,
        type: 'pdf',
        topic: 'Development Books',
        title: 'Book12',
        url: Book12,
        ln:'en'
    },
    {
        id: 48,
        type: 'pdf',
        topic: 'Development Books',
        title: 'bureaucracy and society in Saudi',
        url: bureaucracy_and_society_in_Saudi,
        ln:'en'
    },
    {
        id: 49,
        type: 'pdf',
        topic: 'Development Books',
        title: 'File4',
        url: File4,
        ln:'en'
    },
    {
        id: 50,
        type: 'pdf',
        topic: 'Development Books',
        title: 'File5',
        url: File5,
        ln:'en'
    },
    {
        id: 51,
        type: 'pdf',
        topic: 'Development Books',
        title: '',
        url: '',
        ln:'en'
    },
    {
        id: 52,
        type: 'pdf',
        topic: 'Development Books',
        title: 'File8',
        url: File8,
        ln:'en'
    },
    {
        id: 53,
        type: 'pdf',
        topic: 'Development Books',
        title: 'File9',
        url: File9,
        ln:'en'
    },
    {
        id: 54,
        type: 'pdf',
        topic: 'Development Books',
        title: 'Role of research in policy making',
        url: Role_of_research_in_policy_making,
        ln:'en'
    },
    {
        id: 55,
        type: 'pdf',
        topic: 'Development Books',
        title: 'File10',
        url: File10,
        ln:'en'
    },
    {
        id: 56,
        type: 'pdf',
        topic: 'Development Books',
        title: 'File11',
        url: File11,
        ln:'en'
    },
    {
        id: 57,
        type: 'pdf',
        topic: 'Development Books',
        title: 'A Statment on the human resources planning',
        url: A_Statment_on_the_human_resources_planning,
        ln:'en'
    },
    {
        id: 58,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001 (2)',
        url: file_00001,
        ln:'en'
    },
    {
        id: 59,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001 (3)',
        url: file_00002,
        ln:'en'
    },
    {
        id: 60,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001 (4)',
        url: file_00003,
        ln:'en'
    },
    {
        id: 61,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001 (5)',
        url: file_00004,
        ln:'en'
    },
    {
        id: 62,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001 (6)',
        url: file_00005,
        ln:'en'
    },
    {
        id: 63,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001 (7)',
        url: file_00006,
        ln:'en'
    },
    {
        id: 64,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001 (8)',
        url: file_00007,
        ln:'en'
    },
    {
        id: 65,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001 (9)',
        url: file_00008,
        ln:'en'
    },
    {
        id: 66,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00001',
        url: file_00009,
        ln:'en'
    },
    {
        id: 67,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00002 (2)',
        url: file_00010,
        ln:'en'
    },
    {
        id: 68,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00002',
        url: file_00011,
        ln:'en'
    },
    {
        id: 69,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00003 (2)',
        url: file_00012,
        ln:'en'
    },
    {
        id: 70,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'file_00003',
        url: file_00013,
        ln:'en'
    },
    {
        id: 71,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'File3',
        url: File3,
        ln:'en'
    },
    {
        id: 72,
        type: 'pdf',
        topic: 'JOURNALISM',
        title: 'File7',
        url: File7,
        ln:'en'
    },
]

function Viewer(props) {
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const type = files[props.match.params.id - 1].type
    const file = files[props.match.params.id - 1]


    return (
        <>
            <p className={"text-center mb-3 fs-title-view "+(file.ln=='en'?'':'lang-ff')}>{!file.title ? '' : file.title}</p>
            {/* <h2 className="text-center">{!file.title ? '' : file.title}</h2> */}
            <div className="container container-fix bg px-0 pt-4 pb-4">
                <hr width="100%;" color="#c9c9c7" size="10" />
                <div className="m-5 sm-view-video">{type === "video" &&
                    <video width="100%" height="auto" controls>
                        <source src={file.url} type="video/mp4"></source>
                    </video>
                }{type === "youtube" &&
                    <object width="100%" >
                        <param name="movie" value={files[0].url} />
                        <embed src={file.url} width="100%" />
                    </object>
                    }{type === "pdf" &&
                        <div className="pdf-content">
                            <Document
                                file={file.url}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                        />
                                    ),
                                )}
                            </Document>
                        </div>
                    }
                </div>
                <div style={{ position: 'relative' }}>
                    <hr width="100%;" color="#c9c9c7" size="10" />
                    <img src={Asset_3} className="trademark-comment" />
                </div>
                <div className="m-5 text-right view-pdf">
                    <p className="lang-ff fs-title-view">التعلقات</p>
                    <input className="form-control input-name mb-4 lang-ff float-right text-right" type="name" placeholder="اكتب اسمك" aria-label="Search" />
                    <input className="form-control input-textarea  mb-4 float-right text-right" />

                </div>
                <div className="submit-btn ">
                    <button type="button" className=" form-control btn col-md-3 comment-submit lang-ff ">اضف التعليق</button>
                </div>
            </div>
        </>
    )
}

export default Viewer;