import React from 'react';
import { LanguageContext } from '../../App';
import Asset_3 from '../../assets/Asset 3.svg';
import '../lectures/lectures.css'

const interviews = {
    en:{
        header:'Interviews',
        title:'Post Title',
        category:'Category',
        view:'View',
        viewButton:'View'
    },
    ar:{
        header:'المقابلات',
        title:'العنوان',
        category:'التصنيف',
        view:'عرض',
        viewButton:'لرؤية محتوى '
    }
}
function Interviews(props) {
    const  {lang} = React.useContext(LanguageContext)

    const interviewList = [
        'برنامج وينك. الشاعر والأديب إبراهيم العواجي وكيل وزارة الداخليه سابقا',
        'مقابلة إبراهيم العواجي مع محمد رضا نصرالله في برنامج (هكذا تكلموا) عام 2006م',
        'زوايا مع د. الزير " شخصية وطن " مع د. ابراهيم العواجي	',
        'اضاءات : ابراهيم العواجي	',
        'برنامج رحلة الكلمة مع د.إبراهيم العواجي	',
        'الوجه الآخر الدكتور ابراهيم بن محمد العواجي	',
        'كلنا مسئول عن الوطن | الدكتور/ إبراهيم العواجي | في مجلس الوائلية بالدمام	',
        'د. ابراهيم العواجي في الرسالة اليوم في قناة الرسالة	',
        'الشاعر ابراهيم العواجي يعلنها من أدبي مكة : الشعر لم يعد ديواناً للعرب	',
        'أمسية شعرية أقيمت بنادي مكة الثقافي الأدبي مساء الأربعاء:12/3/1437	',
        'أمسية شعرية	',
        'مقابلة شخصية - مركز الملك فهد الثقافي بالرياض	',
        'مقابلة شخصية	',
        'برنامج السهرة المفتوحة	',
        'امسية شعرية	',
        'مقابلة شخصية	',
        'لقاء مع طلبة معهد الضباط عام 1406 هـ	',
        'مقابلة شخصية	'
    ]
    return (
        <div className="mt-5 topview">
            <p className="text-center ff-bahij title-fs">{interviews[lang].header}</p>
            <div className="container container-fix bg mt-5" style={{ "position": "relative" }}>
                <div className="row align-items-center">
                    <div className={"col-3 col-md-2 pt-4 "+(lang==="en"?"text-left ff-myriad-thin pl2 ":"text-center lang-ff")}>{interviews[lang].category}</div>
                    <div className={"col-6 col-md-7 pt-4 fs-title "+(lang==="en"?"text-left ff-bahij":"text-right lang-ff")}>{interviews[lang].title}</div>
                    <div className={"col-2 col-md-2 text-center pt-4 fs-view  "+(lang==="en"?"ff-myriad-thin":"lang-ff")}>{interviews[lang].view}</div>

                    <hr width="100%;" color="#c9c9c7" size="10" />
                    {interviewList.map((interview,i) => (
                        <>
                            <div className={"col-3 col-md-2 py-3 my-auto "+(lang==="en"?"text-left ff-myriad-thin pl2 sm-title-poem":"text-center lang-ff")}>{interviews[lang].header}</div>
                            <div className={"col-6 col-md-7 pt-2 my-auto fs-title-content-interview "+(lang==="en"?"text-left lang-ff":"text-right lang-ff")}>{interview}</div>
                            <div className="col-2 col-2 py-3 px-0 text-center">
                                <button className={"btn btn-change  "+(lang==="en"?"ff-myriad-thin":"lang-ff")} onClick={()=>props.history.push(`/interviews/view/${i+1}`)}>{interviews[lang].viewButton}</button>
                            </div>
                        </>
                    )
                    )}

                </div>
                <img src={Asset_3} alt="brandImages" className="trademark" />


            </div>
        </div>
    )
}

export default Interviews;